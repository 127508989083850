<template>
  <div class="add-driver">
    <div v-if="tms && tms._id" @click="openModal">
      Serial Number: {{ tms.device_type }}-{{ tms.serial_no }}<br>
      Mobile Number: {{ tms.mobile_no }} <br>
      SIM Number: {{ tms.sim_no }} <br>
      Device Type: {{ tms.device_type }} <br>
      Device Status: {{ tms.status || 'Not Detected' }} <br>
      Server:{{tms.server}}

    </div>
    <b-button v-else @click="openModal" variant="primary"> Add New Terminal </b-button>

    <b-modal :id="`add-terminal-${tms && tms._id}`" title="Terminal" hide-footer>
      <FormulateForm :values="ticketValues" @submit="submitForm" :schema="schema">
        <div class="d-flex justify-content-end">
          <FormulateInput type="submit" :label="addingTicket ? 'Adding..' : 'Add'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import uuid from "@/utils/idGen";
import { terminals } from "@/config/api/terminals";
export default {
  props: ["tms"],
  components: {},
  data() {
    return {
      terminal_id: "",
      addingTicket: false,
      ticketValues: {},
      schema: [
        {
          index: 0,
          type: "number",
          name: "serial_no",
          label: "Serial Number",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          name: "sim_no",
          label: "SIM Number",

        },
        {
          index: 2,
          type: "text",
          name: "mobile_no",
          label: "Mobile Number",

        },

      ],
    };
  },

  computed: {
    uuid() {
      return uuid();
    },
  },

  methods: {

    submitForm(data) {
      let api = terminals.create;
      if (this.tms && this.tms._id) {
        api = terminals.update
        api.id = this.tms._id
      }
      api.data = data;
      this.submitting = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Terminal finished successfully", {
            title: "Wrapping Job",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide(`add-terminal-${this.tms && this.tms._id}`);
          this.$emit("reload");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Terminal is not finished!, ${err.response.data.error.message}!`,
            {
              title: "Wrapping Job",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    async openModal() {

      this.ticketValues = {};
      if (this.tms && this.tms._id) {
        this.ticketValues = this.tms
      }
      this.$bvModal.show(`add-terminal-${this.tms && this.tms._id}`);
    },
  },
};
</script>

<style>

</style>
